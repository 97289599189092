import { Flex, HStack, Stack, Tabs, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getDashboardOnboardingStepStatus,
  getNexus,
  NEXUS_STATE_KEY,
  NON_EXPOSED_NEXUS_STATE_KEY,
  ONBOARDING_STATE_KEY,
} from 'apis/dashboard-apis';
import { usePaywall } from 'app/acl/paywall';
import AppHeader from 'app/app-header';
import NexusHeaderToolbar from 'app-header-toolbar/nexus-tracking-toolbar';
import AlertBanner from 'components/alert/alert';
import ImportProgressAlert from 'components/import-progess-alert/import-progress-alert';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { Button } from 'components/ui/button';
import { NEXUS_ROUTES } from 'constants/AppRoutes';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import { SetupPhysicalMailModal } from 'pages/Configuration/components/communications/setup-physical-mail-modal';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import { OnBoardingStepStatus } from 'types/onboarding';
import { NexusInstance, NexusStatus, PhysicalMailStatus } from 'types/shared-types';

import { ExposedNexusTable } from './components/exposed-nexus-table';
import { NonExposedNexusTable } from './components/non-exposed-nexus-table';

const checkNexusWaitingStatus = (items: NexusInstance[] = []) => {
  return items.some(item => item.processing_status === 'NOT_READY' && item.transaction_count > 0);
};

const Nexus = () => {
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { open, onOpen, onClose } = useDisclosure();

  const nonExposedTableFilters = useTableFilters({
    status__in: [NexusStatus.NOT_EXPOSED, NexusStatus.APPROACHING],
  });

  const {
    page: nonExposedPage,
    size: nonExposedSize,
    status__in: nonExposedStatus__in,
    setFilters: nonExposedSetFilters,
  } = nonExposedTableFilters;

  const exposedTableFilters = useTableFilters({
    status__in: [NexusStatus.EXPOSED, NexusStatus.PENDING_REGISTRATION, NexusStatus.REGISTERED],
  });
  const { page, size, status__in, setFilters } = exposedTableFilters;

  const { isLoading: isExposedNexusLoading, data: exposedNexusRes } = useQuery({
    queryKey: [NEXUS_STATE_KEY, orgId, page, size, status__in],
    queryFn: async () => {
      const response = await getNexus({
        orgId,
        params: {
          page,
          size,
          status__in,
        },
      });
      return response.data ?? {};
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });

  const { isLoading: isNonExposedNexusLoading, data: nonExposedNexusRes } = useQuery({
    queryKey: [NON_EXPOSED_NEXUS_STATE_KEY, orgId, nonExposedPage, nonExposedSize, nonExposedStatus__in],
    queryFn: async () => {
      const response = await getNexus({
        orgId,
        params: {
          page: nonExposedPage,
          size: nonExposedSize,
          status__in: nonExposedStatus__in,
        },
      });
      return response.data ?? {};
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });

  const { data: stepData, isLoading: isStepStatusLoading } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data satisfies OnBoardingStepStatus;
    },
    enabled: !!orgId,
  });

  const isExposedNexusWaiting = useMemo(() => checkNexusWaitingStatus(exposedNexusRes?.items || []), [exposedNexusRes]);
  const isNonExposedNexusWaiting = useMemo(
    () => checkNexusWaitingStatus(nonExposedNexusRes?.items || []),
    [nonExposedNexusRes]
  );

  const handleRegisterClick = async () => {
    if (!isPaidUser && !isTest) {
      setSearchParams({ openPricingModal: 'true' });
    } else if (isPaidUser && !physicalMailComplete && !isTest) {
      onOpen();
    } else {
      setSearchParams({ requestRegistration: 'true', requestRegSource: 'not-exposed' });
    }
  };

  const onboardingComplete = stepData?.bank_details_status && stepData?.organization_details_status;
  const physicalMailComplete =
    stepData?.physical_mail_address_status === PhysicalMailStatus.complete ||
    stepData?.physical_mail_address_status === PhysicalMailStatus.verify;

  const notReadyAlertContent = (
    <AlertBanner
      width={{ xl: '80%' }}
      message="We are not able to calculate Nexus for some jurisdictions because there are either too many unapproved
          Products or Invalid addresses"
    />
  );

  const isExposedPending = isExposedNexusLoading || isStepStatusLoading;
  const exposedNexusItems = exposedNexusRes?.items ?? [];
  const exposedTotalPages = exposedNexusRes?.pages ?? 0;
  const isExposedPaginationEnable = exposedNexusItems.length && (exposedNexusRes?.total ?? 0) > DEFAULT_PAGE_SIZE;

  const isNonExposedPending = isNonExposedNexusLoading || isStepStatusLoading;
  const nonExposedNexusItems = nonExposedNexusRes?.items ?? [];
  const nonExposedTotalPages = nonExposedNexusRes?.pages ?? 0;
  const isNonExposedNexusPaginationEnable =
    nonExposedNexusItems.length && (nonExposedNexusRes?.total ?? 0) > DEFAULT_PAGE_SIZE;

  const tabConfigs = [
    {
      routePath: NEXUS_ROUTES.EXPOSED,
      title: `Exposed (${exposedNexusRes?.total ?? 0})`,
      content: (
        <Stack height="100%" key="exposed-nexus">
          {isExposedNexusWaiting && notReadyAlertContent}
          <ImportProgressAlert />
          <ExposedNexusTable
            data={exposedNexusItems}
            onboardingComplete={onboardingComplete}
            physicalMailComplete={physicalMailComplete}
            isPending={isExposedPending}
          />
          {isExposedPaginationEnable && (
            <PaginationButtons size={size} currentPage={page} totalPages={exposedTotalPages} setFilters={setFilters} />
          )}
        </Stack>
      ),
    },
    {
      routePath: NEXUS_ROUTES.NOT_EXPOSED,
      title: `Not Exposed (${nonExposedNexusRes?.total ?? 0})`,
      content: (
        <Stack height="100%" key="non-exposed-nexus" gap={4}>
          <Flex direction={{ base: 'column', md: 'row', xl: 'row' }} gap={2} justify={'space-between'}>
            <Flex flexDirection={'column'} gap={2} w={{ base: '100%', md: '100%' }}>
              {isNonExposedNexusWaiting && notReadyAlertContent}
              <ImportProgressAlert />
            </Flex>
            <Flex justifyContent={'flex-end'} w={{ base: '100%', md: '10%' }} mt={2}>
              {isPaidUser && nonExposedNexusItems.length > 0 && (
                <Button
                  width="120px"
                  fontSize="sm"
                  variant="outline"
                  onClick={() => {
                    handleRegisterClick();
                  }}
                  py={1}
                  px={2}
                >
                  Register
                </Button>
              )}
            </Flex>
          </Flex>
          <NonExposedNexusTable data={nonExposedNexusItems} isPending={isNonExposedPending} />
          {isNonExposedNexusPaginationEnable && (
            <PaginationButtons
              size={nonExposedSize}
              currentPage={nonExposedPage}
              totalPages={nonExposedTotalPages}
              setFilters={nonExposedSetFilters}
            />
          )}
        </Stack>
      ),
    },
  ];

  const currentIndex = tabConfigs.findIndex(t => t.routePath === location.pathname);
  const [tabIndex] = useState(currentIndex === -1 ? 0 : currentIndex);

  return (
    <>
      <HStack gap={1} justifyContent={'space-between'}>
        <AppHeader />
        <NexusHeaderToolbar />
      </HStack>
      <Stack
        h="100%"
        css={{
          '& .chakra-tabs, & .chakra-tabs__tab-panels>.chakra-tabs__tab-panel': {
            height: '100%',
          },
          '& .chakra-tabs__tab-panels': {
            height: 'calc(100% - 2.5rem)',
          },
        }}
      >
        <Tabs.Root defaultValue={tabConfigs[tabIndex].routePath} colorPalette="blue">
          <Tabs.List>
            {tabConfigs.map(tab => {
              return (
                <Tabs.Trigger _selected={{ color: 'secondary.500' }} key={tab.routePath} value={tab.routePath} asChild>
                  <RouterLink to={tab.routePath}>{tab.title}</RouterLink>
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
          {tabConfigs.map(tab => {
            return (
              <Tabs.Content key={tab.routePath} value={tab.routePath}>
                {tab.content}
              </Tabs.Content>
            );
          })}
        </Tabs.Root>
      </Stack>
      {open && <SetupPhysicalMailModal isOpen={open} onClose={onClose} isNexus={true} />}
    </>
  );
};

export default Nexus;
