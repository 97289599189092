import { HStack, Icon, Table, Text } from '@chakra-ui/react';
import { CalendarIcon, InfoIcon, WarningIcon } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { ProgressCircleRing, ProgressCircleRoot } from 'components/ui/progress-circle';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { Tooltip } from 'components/ui/tooltip';
import { useNexusSelection } from 'hooks/useNexusSelection';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { MdOutlineAnalytics } from 'react-icons/md';
import { NexusInstance, NexusStatus, SalesOrTransactions } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { formatCurrency } from 'utils/utils';

import { NexusPredictions } from './nexus-predictions';
import { NexusStateBadge } from './nexus-state-badge';
import NexusDetailsDrawer from './NexusDetailsDrawer';

const TABLE_HEAD = ['Country', 'Jurisdiction', 'Dollar Threshold', 'Transaction Threshold', 'Status'];
const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD;
const COLUMN_WIDTHS = {
  Country: '130x',
  Jurisdiction: '13.75rem',
  'Dollar Threshold': '18.75rem',
  'Transaction Threshold': '17.5rem',
  Status: '10rem',
};

export const NonExposedNexusTable = ({ data, isPending }: { data: NexusInstance[]; isPending: boolean }) => {
  const isDataEmpty = !data || data.length === 0;
  const { selectedNexus, handleDrawerClose, handleDrawerOpen } = useNexusSelection(data);

  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);

  const handleVisibleColumnsChange = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  if (isDataEmpty && !isPending) {
    return <TableEmptyState tableName="Nexus" key="nexus-not-exposed" />;
  }

  return (
    <>
      <KDataTable
        headers={TABLE_HEAD}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        showColumnFilter
        columnWidths={COLUMN_WIDTHS}
        isPending={isPending}
      >
        {data.map((nexus: NexusInstance) => {
          return (
            <NexusTableRow
              key={`${nexus.country_code}-${nexus.state_name}`}
              onDrawerOpen={handleDrawerOpen}
              nexus={nexus}
              visibleColumns={visibleColumns}
            />
          );
        })}
        {isPending && <TableRowSkeleton length={25} columns={visibleColumns} />}
      </KDataTable>
      {selectedNexus && !isEmpty(selectedNexus.periods) && (
        <NexusDetailsDrawer isOpen onClose={handleDrawerClose} nexus={selectedNexus} />
      )}
    </>
  );
};

type NexusTableRowProps = {
  onDrawerOpen: (nexus: NexusInstance) => void;
  nexus: NexusInstance;
  visibleColumns?: string[];
};

const PROGRESS_COLORS = {
  EMPTY: 'gray.100',
  COMPLETE: 'primary.300',
  IN_PROGRESS: 'secondary.400',
} as const;

const NexusTableRow = ({ onDrawerOpen, nexus, visibleColumns }: NexusTableRowProps) => {
  const {
    country_code,
    state_code,
    state_name,
    transactions_amount,
    threshold_sales,
    transaction_count,
    threshold_transactions,
    nexus_met,
    nexus_met_date,
    tax_liability,
    sales_or_transactions,
    processing_status,
    status,
    predicted_month_from_today,
    confidence_level,
  } = nexus;
  const isNexusMet = Boolean(nexus_met);

  const getProgressCircleColor = (transactions_amount: number) => {
    const formattedAmount = Number(transactions_amount).toFixed(2);
    if (isNexusMet) return PROGRESS_COLORS.COMPLETE;
    if (formattedAmount === '0.00') return PROGRESS_COLORS.EMPTY;
    return PROGRESS_COLORS.IN_PROGRESS;
  };

  return (
    <Table.Row>
      {visibleColumns?.includes('Country') && (
        <Table.Cell>
          <Text lineClamp={1}>{getCountryDisplay(country_code)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Jurisdiction') && (
        <Table.Cell>
          <HStack align="center" gap={1}>
            <Text>{state_code}</Text>
            {processing_status === 'NOT_READY' && (
              <Tooltip
                content={
                  'We are not able to calculate Nexus because there are either too many unapproved products or invalid addresses.'
                }
              >
                <Icon fontSize={'18px'} mt={1}>
                  <WarningIcon />
                </Icon>
              </Tooltip>
            )}
            {SalesOrTransactions.BOTH === sales_or_transactions && (
              <Tooltip
                content={
                  'In this Jurisdiction businesses must meet both the Dollar Threshold and Transaction Threshold to establish nexus.'
                }
              >
                <Icon fontSize={'18px'}>
                  <InfoIcon />
                </Icon>
              </Tooltip>
            )}
          </HStack>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Dollar Threshold') && (
        <Table.Cell>
          {threshold_sales !== null ? (
            <HStack>
              <ProgressCircleRoot
                size={'sm'}
                value={Math.min(Math.max((transactions_amount / threshold_sales) * 100, 0), 100)}
                color={isNexusMet ? PROGRESS_COLORS.COMPLETE : PROGRESS_COLORS.IN_PROGRESS}
              >
                <ProgressCircleRing
                  css={{ '--thickness': '4px' }}
                  trackColor={PROGRESS_COLORS.EMPTY}
                  cap={'round'}
                  color={getProgressCircleColor(transactions_amount)}
                />
              </ProgressCircleRoot>

              <Text>
                {formatCurrency(transactions_amount)} of {formatCurrency(threshold_sales)}
              </Text>
              {isNexusMet && (
                <Tooltip
                  content={
                    <>
                      Nexus met: {nexus_met_date}
                      <br></br>
                      Tax liability: ${tax_liability}
                    </>
                  }
                >
                  <span>
                    <CalendarIcon />
                  </span>
                </Tooltip>
              )}
            </HStack>
          ) : (
            <Tooltip content={`${state_name} does not have a dollar threshold`}>
              <Text>No dollar threshold</Text>
              <Icon boxSize={'16px'}>
                <InfoIcon />
              </Icon>
            </Tooltip>
          )}
        </Table.Cell>
      )}
      {visibleColumns?.includes('Transaction Threshold') && (
        <Table.Cell>
          {threshold_transactions !== null ? (
            <HStack>
              <ProgressCircleRoot
                size={'sm'}
                value={Math.min(Math.max((transaction_count / threshold_transactions) * 100, 0), 100)}
                color={isNexusMet ? PROGRESS_COLORS.COMPLETE : PROGRESS_COLORS.IN_PROGRESS}
              >
                <ProgressCircleRing
                  css={{ '--thickness': '4px' }}
                  trackColor={PROGRESS_COLORS.EMPTY}
                  cap={'round'}
                  color={getProgressCircleColor(transaction_count)}
                />
              </ProgressCircleRoot>
              <Text ml={'10px'}>
                {transaction_count} of {threshold_transactions}
              </Text>
            </HStack>
          ) : (
            <HStack>
              <Text>No transactions threshold</Text>
              <Tooltip content={`${state_name} does not have a transaction threshold.`}>
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </HStack>
          )}
        </Table.Cell>
      )}
      {visibleColumns?.includes('Status') && (
        <Table.Cell>
          <HStack>
            <NexusStateBadge status={status as NexusStatus} />
            <NexusPredictions
              stateCode={state_code}
              countryCode={country_code}
              prediction={{
                predicted_month_from_today: predicted_month_from_today || 0,
                confidence_level: confidence_level || 0,
              }}
            />
          </HStack>
        </Table.Cell>
      )}
      <Table.Cell width="4.5rem" textAlign="-webkit-right">
        <Tooltip
          content={
            !isEmpty(nexus.periods)
              ? `Economic Nexus Details`
              : 'Economic Nexus Details are unavailable because there are no transactions for this jurisdiction yet'
          }
        >
          <MdOutlineAnalytics
            size={24}
            cursor={!isEmpty(nexus.periods) ? 'pointer' : 'not-allowed'}
            onClick={ev => {
              ev.stopPropagation();
              if (!isEmpty(nexus.periods)) {
                onDrawerOpen(nexus);
              }
            }}
          />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
};
